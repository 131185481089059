<template>
  <section class="q-pa-md">
    <div>Redirecting, please wait...</div>
    <q-btn
      color="primary"
      label="Click here if it takes too long"
      to="/en-us"
    />
  </section>
</template>

<script>
  export default {
    async mounted () {
      await this.$router.push('/en-us')
    },
  }
</script>
